/**
 * BxSlider v4.1.2 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2014
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */


/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
	position: relative;
	background: rgba(0, 0, 0, 0.7);
	padding: 0;
	*zoom: 1;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
}

.bx-wrapper img {
	max-width: none;
	display: block;
	position: absolute;
}
.bxslider {
	margin: 0;
	padding: 0;
	height: 100vh;
}
.bxslider li {
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	overflow: hidden;
	height: 100vh;
	padding: 0;
}
ul.bxslider {
	list-style: none;
}
.bx-viewport {
	/*fix other elements on the page moving (on Chrome)*/
	-webkit-transform: translatez(0);
}

/** THEME
===================================*/

.bx-wrapper .bx-viewport {
	background: #fff;
	
	/*fix other elements on the page moving (on Chrome)*/
	-webkit-transform: translatez(0);
	-moz-transform: translatez(0);
    	-ms-transform: translatez(0);
    	-o-transform: translatez(0);
    	transform: translatez(0);
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
	position: absolute;
	bottom: 40px;
	width: 100%;
	z-index: 2;
}

/* LOADER */

.bx-wrapper .bx-loading {
	min-height: 50px;
	background: url(../images/bx_loader.gif) center center no-repeat #fff;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
}

/* PAGER */
.bx-wrapper .bx-pager {
	text-align: center;
	font-size: .85em;
	font-weight: bold;
	color: #666;
}
.bx-wrapper .bx-pager.bx-default-pager a {
	text-indent: -9999px;
	border: 2px solid #e45914;
	display: block;
	width: 15px;
	height: 15px;
	margin: 0 5px;
	outline: 0;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}
.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus {
	background: #e45914;
}
.bx-wrapper .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
	display: inline-block;
	*zoom: 1;
	*display: inline;
}
.bx-wrapper .bx-pager-item {
	font-size: 0;
	line-height: 0;
}
/* DIRECTION CONTROLS (NEXT / PREV) */

.bx-wrapper .bx-prev {
	left: 10px;
	padding: 8px 11px;
	transition: all 0.2s linear 0s;
	-moz-transition: all 0.2s linear 0s;
	-webkit-transition: all 0.2s linear 0s;
	-ms-transition: all 0.2s linear 0s;
	-o-transition: all 0.2s linear 0s;
}

.bx-wrapper .bx-next {
	right: 10px;
	padding: 8px 15px;
	transition: all 0.2s linear 0s;
	-moz-transition: all 0.2s linear 0s;
	-webkit-transition: all 0.2s linear 0s;
	-ms-transition: all 0.2s linear 0s;
	-o-transition: all 0.2s linear 0s;
}
.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-prev:hover {
	color: #e45914;
}
.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-next:focus {
	background-position: -43px 0;
}
.bx-wrapper .bx-prev:hover {
	background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
	background-position: -43px 0;
}
.bx-wrapper .bx-controls-direction a {
	position: absolute;
	top: 50%;
	margin-top: -16px;
	outline: 0;
	z-index: 70;
	font-size: 32px;
	color: #b0b2b6;
	text-decoration: none;
}
.bx-wrapper .bx-controls-direction a.bx-next:before {
	content: "\f105";
	font-family: 'FontAwesome';
}
.bx-wrapper .bx-controls-direction a.bx-prev:before {
	content: "\f104";
	font-family: 'FontAwesome';
}
.bx-wrapper .bx-controls-direction a.disabled {
	display: none;
}

/* AUTO CONTROLS (START / STOP) */

.bx-wrapper .bx-controls-auto {
	text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
	display: block;
	text-indent: -9999px;
	width: 10px;
	height: 11px;
	outline: 0;
	background: url(../images/controls.png) -86px -11px no-repeat;
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
	background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
	display: block;
	text-indent: -9999px;
	width: 9px;
	height: 11px;
	outline: 0;
	background: url(../images/controls.png) -86px -44px no-repeat;
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
	background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
	text-align: left;
	width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
	right: 0;
	width: 35px;
}

/* IMAGE CAPTIONS */

.bx-wrapper .bx-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	background: #666\9;
	background: rgba(80, 80, 80, 0.75);
	width: 100%;
}
.bx-wrapper .bx-caption span {
	color: #fff;
	display: block;
	font-size: .85em;
	padding: 10px;
}
